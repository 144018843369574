import {navigateTo, nextTick, useCookie, useRuntimeConfig, useState} from "#imports";
import {CookieRef} from "#app";
import {Auth0Interface, Auth0User} from "~/src/auth/types";
import {Ref} from "@vue/reactivity";
import {WebAuth} from "auth0-js";

export const useAuth0BearerToken = (): CookieRef<string> => useCookie<string>('HB_TOKEN')

const useAuth0User = (): Ref<Auth0User | null> => useState('HB_USER', () => null);

export const useAuth0 = (): Auth0Interface => {
    const config = useRuntimeConfig();
    const user = useAuth0User();
    const token = useAuth0BearerToken();

    const setUser = (value: Auth0User | null): void => {
        user.value = value;
    }

    const getUser = async (): Promise<Ref<Auth0User | null>> => {
        if (!user.value && !!token.value) {
            try {
                setUser(await $fetch('/userinfo', {
                    baseURL: config.public.auth0Domain,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token.value}`,
                    }
                }))
            } catch (e: any) {
                setUser(null);
            }
        }

        return user;
    }

    const logout = async (redirectTo: string): Promise<void> => {
        useAuth0BearerToken().value = null;

        const redirectUrl = new URL('/v2/logout', config.public.auth0Domain);
        redirectUrl.searchParams.set('client_id', config.public.auth0Client);
        redirectUrl.searchParams.set('returnTo', redirectTo);

        await nextTick(() => navigateTo(redirectUrl.href, {external: true}));
    }

    const authorize = (redirectTo: string, email?: string): void => {
        const webAuth = new WebAuth({
            domain: config.public.auth0Domain,
            clientID: config.public.auth0Client,
            scope: 'openid email profile user_metadata',
            responseType: 'token',
            responseMode: 'fragment',
        });

        return webAuth.authorize({
            audience: config.public.auth0Audience,
            redirectUri: redirectTo,
            login_hint: email,
            connection: 'email',
        })
    }

    return {
        getUser,
        setUser,
        logout,
        authorize,
    }
}
